/* eslint-disable */
<template>
	<v-data-table
		:headers="headers"
		:items="walletList"
		:items-per-page="10"
		:hide-default-footer="walletList.length <= 10">
		<template v-slot:item="{ item }">
			<tr>
				<td>
					{{item.name}}
				</td>
				<td>
                    <v-avatar
                        class="mr-1"
                        size="20">
                        <img
                            :src="getLogo(item.coin)"
                            :alt="item.coin">
                    </v-avatar>
                    {{$_.upperCase(item.coin)}}
				</td>
				<td>
                    <code>
                        {{item.address}}
                    </code>
				</td>
                <td>
                    <template v-if="item.created">
                        {{ item.created | moment("MMMM Do YYYY, h:mm:ss a") }}
                    </template>
                    <template v-else>
                        Unknown
                    </template>
                </td>
				<td align="end">
					<template v-if="user.id">
						<v-btn
							icon
							@click="addWallet({edit: true, wallet: item})">
							<v-icon
								small
								color="warning">
								mdi-pencil
							</v-icon>
						</v-btn>
						<v-btn
							icon
							@click="deleteWallet(item.id)">
							<v-icon
								small
								color="error">
								mdi-delete
							</v-icon>
						</v-btn>
					</template>
				</td>
			</tr>
		</template>
	</v-data-table>
</template>

<script>
export default {
	data() {
		return {
			headers: [
				{
					text: 'Name',
					value: 'name'
				},
				{
					text: 'Coin',
					value: 'coin'
				},
				{
					text: 'Address',
					value: 'address'
				},
                {
                    text: 'Created',
                    value: 'created'
                },
				{
					text: 'Actions',
					sortable: false,
					align: 'end'
				}
			]
		}
	}
}
</script>