<template>
	<v-container>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
		<v-row>
			<v-col>
				<v-card>
					<v-card-title>
						<v-icon class="mr-2">
							mdi-wallet
						</v-icon>
						Wallets
                        <v-btn-toggle
                            dense
                            class="ml-6"
                            mandatory
                            v-model="view">
                            <v-btn small>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            mdi-view-grid
                                        </v-icon>
                                    </template>
                                    <span>
                                        Grid View
                                    </span>
                                </v-tooltip>
                            </v-btn>
                            <v-btn small>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            mdi-table
                                        </v-icon>
                                    </template>
                                    <span>
                                        Table View
                                    </span>
                                </v-tooltip>
                            </v-btn>
                        </v-btn-toggle>
						<v-spacer>
						</v-spacer>
						<v-btn
							color="primary"
							@click="addWallet()">
							Add Wallet
						</v-btn>
					</v-card-title>
					<v-card-text>
                        <v-row>
                            <v-col>
                                <p class="caption mb-6">
                                    Your wallet address is used to tell the pool where to send your earned coins to.  Your wallet address is very important so make sure you double check it to make sure it is correct.  The pool also uses your wallet address for your statistics and to identify your account.  <span class="warning--text">If you change your wallet address the pool will not associate old wallet earnings with the new address.</span>
                                </p>
                            </v-col>
                        </v-row>
						<v-card
                            v-if="view === 1"
							falt
							outlined>
							<wallet-table>
							</wallet-table>
						</v-card>
                        <v-row
                            dense
                            v-if="view === 0 && walletList">
                            <v-col
                                cols="12"
                                md="6"
                                v-for="(item, key) in walletList"
                                :key="key">
                                <wallet-card :item="item">
                                </wallet-card>
                            </v-col>
                        </v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="3484124564"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
	</v-container>
</template>

<script>
    import WalletCard from './../components/wallets/WalletCard'
    import WalletTable from './../components/wallets/WalletTable'
    import Ad from '../components/ads/GeneralAd'

    export default {
        metaInfo: {
            titleTemplate: 'Wallets - %s',
            meta: [
                { name: 'description', content: 'Set your wallet address for Monero XMR and Masari MSR coins.' },
            ]
        },
        data() {
            return {
                view: 0
            }
        },
        components: {
            WalletCard,
            WalletTable,
            Ad
        }
    }
</script>

<style lang="scss" scoped>
    code { word-break: break-all }
</style>